import { useEffect } from 'react'
import { localStore } from '@flock/utils'
import { HOMEPAGE_URL } from '../constants'

const DisableTracking = () => {
  useEffect(() => {
    localStore.clear()
    localStore.setItem('disableTracking', 'true')

    let redirectUrl = HOMEPAGE_URL
    if (typeof window !== 'undefined') {
      const { search } = window.location
      const params = new URLSearchParams(search)
      redirectUrl = params.get('redirectUrl') || HOMEPAGE_URL
    }

    window.location.href = redirectUrl
  }, [])

  return null
}

export default DisableTracking
